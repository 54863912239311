
/** Backgrounds **/
$eng-color--background: #f2f2f3;
$eng-color--background-light: #f9f9f9;
$eng-color--background-dark: #778899;

/** Text **/
$eng-color__title--light: aliceblue;
$eng-color__text--dark: #262626;

// tables, widgets, menus
$component-color--background: #243447;

$component-color--background-opaque: rgba(112, 109, 109, 0.89);
$component-color--background-opaque-light: rgba(247, 245, 245, 0.89);

$eng-color--active: #152985;
/* You can add global styles to this file, and also import other style files */
@import 'core';
@import 'saturn-datepicker/theming';
@include sat-datepicker-theme($light-theme);
@import '~@angular/material/theming';

// Define custom primary palette
$custom-primary: mat-palette((
  50: #E1F5FE,
  100: #B3E5FC,
  200: #81D4FA,
  300: #4FC3F7,
  400: #29B6F6,
  500: #03A9F4,
  600: #039BE5,
  700: #0288D1,
  800: #0277BD,
  900: #01579B,
  A100: #80D8FF,
  A200: #40C4FF,
  A400: #00B0FF,
  A700: #0091EA,
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: rgba(0, 0, 0, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(0, 0, 0, 0.87),
    A200: rgba(0, 0, 0, 0.87),
    A400: rgba(0, 0, 0, 0.87),
    A700: white,
  )
));

// Define custom accent palette
$custom-accent: mat-palette((
  50: #ECEFF1,
  100: #CFD8DC,
  200: #B0BEC5,
  300: #90A4AE,
  400: #78909C,
  500: #607D8B,
  600: #546E7A,
  700: #455A64,
  800: #37474F,
  900: #263238,
  A100: #CFC8DC,
  A200: #B0BEC5,
  A400: #78909C,
  A700: #455A64,
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(0, 0, 0, 0.87),
    A200: rgba(0, 0, 0, 0.87),
    A400: white,
    A700: white,
  )
));


$custom-theme: mat-light-theme((
  color: (
    primary: $custom-primary,
    accent: $custom-accent,
  )
));

@include angular-material-theme($custom-theme);
@include angular-material-typography($custom-theme);

eng-root {
    background: white;
}

body {
    margin: 0;
    @include eng-getTextStyles('medium');
}

.mat-mini-fab .mat-button-wrapper {
    color: black;
  }

.eng-theme--dark {
    @include angular-material-theme($dark-theme);
    background: $component-color--background !important;
    .mat-drawer,
    .mat-expansion-panel {
        background: #243447 !important;
    }
    .mat-menu-panel {
        background: $component-color--background-opaque !important;
    }
    text {
        fill: white;
    }
}

.eng-theme--light {
    @include angular-material-theme($light-theme);
    .mat-menu-panel {
        background: white !important;
    }
}

.eng-theme--light .mat-mini-fab.mat-accent {
    background-color: white;
}

eng-sidenav {
    .mat-expansion-panel-body {
        padding-bottom: 0 !important;
    }
}


.eng-login__wrapper {
    @include angular-material-theme($my-login-theme);
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
-webkit-text-fill-color: white;
transition: background-color 5000s ease-in-out 0s;
}


// .mat-drawer
// .mat-paginator,
// .mat-table {
//     background: $component-color--background !important;
// }
// @include sat-datepicker-theme($my-theme);
// .eng-theme--dark {
//     // color: #9fadb7;
//     // fill: #9fadb7;
//     background: $component-color--background !important;
//     color: white;
//     fill: white;
//     // background: #424242 !important;
// }

// .eng-theme--light {
//     background: black;
// }

::ng-deep {
    .mat-primary {
        background: #03a9f4 !important;
        color: white;
    }
    
    .mat-accent {
        background: #607d8b !important;
        color: white;
    }
}
$eng-font__weights: (
  extra-light: 100,
  light: 100,
  slim: 200,
  thin: 300,
  normal: 400,
  semibold: 600,
  bold: 700,
);

$eng-font__sizes: (
  tiny: 12px,
  small: 14px,
  normal: 16px,
  medium: 18px,
  medium-large: 20px,
  large: 24px,
  xlarge: 32px,
  xxlarge: 48px,
  xxxlarge: 64px,
);

@function eng-getTextWeight($weight: 'normal') {
  @return map-get($eng-font__weights, $weight);
}

@function eng-getFontSize($weight: 'normal') {
  @return map-get($eng-font__sizes, $weight);
}

$eng-line__heights: (
  tiny: 1.5em,
  small: 1.5em,
  normal: 1.5em,
  medium: 1.5em,
  medium-large: 1.5em,
  large: 1.3em,
  xlarge: 1.3em,
  xxlarge: 1.2em,
) !default;

@mixin eng-getTextStyles($size: 'normal', $weight: 'normal') {
  font-family: 'Roboto', sans-serif;
  font-size: map-get($eng-font__sizes, $size);
  line-height: map-get($eng-line__heights, $size);
  font-weight: eng-getTextWeight($weight);
  cursor: default;
}
